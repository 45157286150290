import api from '@api'
import { generateId } from '@utils/id'
import {
  transformAssetForDetail,
  transformAssetForServer,
  transformAssetForList,
  transformAssetComponentForServer,
  transformAssetComponent,
  transformAssetProperties,
  transformVmForDetail,
  transformSummaryBaseline,
  // transformSummaryBaselineForServer,
  transformBaselineHistory,
  transformBaselineCompareInfo,
  transformVarianceHistory,
  transformSummaryVariance,
  transformSoftwareUninstallTask,
  transformSoftwareUninstallTasForServer,
  transformRdpHistory,
  transformUsedByOrLocationHistory,
  transformGeolocationHistory,
  transformGeolocationCoordinates,
  transformAssetSystemAction,
  transformAssetUtilizationHistory,
} from '@data/asset'
import { transformTicketForServer } from '@data/ticket'
import {
  transformCost,
  transformCostForServer,
  transformDepreciation,
  transformAssetDepreciationForList,
  transformCostInfo,
} from '@data/depreciation'
import { transformSnmpPropertyValue } from '@data/snmp-property'
import { transformRDPRecording } from '@data/rdp-configuration'
import { buildImageUrlFromRefFileName } from '@data/attachment'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
  buildNameFilterQuery,
} from '@data/qualification'

import { transformScanHistory } from '@data/scan-history'
import { transformChangeLog } from '@data/change-log'
import { transformWolHistory } from '@data/wol-history'
import { successToast, errorToast } from '@motadata/ui'
import {
  getRootTranslator,
  getRootPluaralTranslator,
  getModuleTranslator,
  getModulePluaralTranslator,
} from '@/src/utils/get-module-translator'
import { transformRecursive } from '@data/recursive'
import { setItem as localStoreSetItem } from '@utils/localstore'
import { transformMovementAndRepairHistory } from '@data/asset-movement'
import moduleConfig from './config'
import { isPortalLogin } from '@utils/auth'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()
const __mtc = getModuleTranslator(moduleConfig.translationKey)
const __mtcp = getModulePluaralTranslator(moduleConfig.translationKey)

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(buildNameFilterQuery(qualificationFactors.name))
  }
  return buildFlatQualificationStructure(quals)
}

const buildAssetIdQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

const buildQualificationsForHistoryDrawers = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.type) {
    quals.push(
      buildRelationalQualificationStructure(
        'fieldType',
        'equal',
        qualificationFactors.type,
        'enum',
        'db'
      )
    )
  }
  if (qualificationFactors.ids) {
    quals.push(
      buildRelationalQualificationStructure(
        'refId',
        'in',
        qualificationFactors.ids,
        'long',
        'db'
      )
    )
  }
  if (qualificationFactors.moduleName) {
    quals.push(
      buildRelationalQualificationStructure(
        'refModel',
        'equal',
        qualificationFactors.moduleName,
        'enum',
        'db'
      )
    )
  }
  if (qualificationFactors.approvalStatus) {
    quals.push(
      buildRelationalQualificationStructure(
        'approvalStatus',
        'equal',
        qualificationFactors.approvalStatus,
        'enum',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function searchAssetsApi(
  moduleName,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/asset/${moduleName}/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchComputerAssetsApi(
  moduleName,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/asset/${moduleName}/Computer/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getApi(
  moduleName,
  id,
  additionalUrlParams = {},
  notify = true
) {
  return api
    .get(`/asset/${moduleName}/${id}`, {
      params: {
        ...additionalUrlParams,
      },
      notify,
    })
    .then(transformAssetForDetail)
}
export function myAssetsUserByIdApi(id) {
  return api.get(`/myasset/user/${id}`).then((data) => {
    return {
      items: data.objectList
        ? (data.objectList || []).map((i) =>
            transformAssetForList({ ...i, relationId: 'fake_id' })
          )
        : [],
      total: data.totalCount,
    }
  })
}

export function updateApi({ id, ...change }, type, action) {
  return api
    .patch(`/asset/${id}`, transformAssetForServer(change), {
      message: __rootT(
        action === 'delete' ? 'deleted_successfully' : 'updated_successfully',
        {
          resource: __rootTc(type || 'asset', 1),
        }
      ),
    })
    .then(transformAssetForDetail)
}

export function createApi(request) {
  return api.post('/asset', transformAssetForServer(request), {
    message: __rootT('added_successfully', {
      resource: __rootTc('asset', 1),
    }),
  })
}

export function bulkUpdateApi(moduleName, ids, data, type, action) {
  const payload = {
    objectIds: ids,
    updateMap: transformAssetForServer(data),
  }
  return api
    .patch(`asset/${moduleName}/bulk/update`, payload, {
      notify: true,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          `${__rootT(
            data.successIds.length > 1
              ? action === 'add'
                ? 'added_successfully_multiple'
                : 'updated_successfully_multiple'
              : action === 'add'
              ? 'added_successfully'
              : 'updated_successfully',
            {
              resource: __rootTc(type || 'asset', data.successIds.length),
            }
          )}`
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function deleteAssetApi(moduleName, id, additionalUrlParams = {}) {
  return api.delete(`/asset/${moduleName}/${id}`, {
    params: {
      ...additionalUrlParams,
    },
    message: __rootT('archived_successfully', {
      resource: __rootTc(moduleName),
    }),
  })
}

export function bulkDeleteApi(moduleName, ids, additionalUrlParams = {}) {
  return api
    .delete(`/asset/${moduleName}/bulk/delete`, {
      data: { objectIds: ids },
      params: {
        ...additionalUrlParams,
      },
      notify: false,
    })
    .then((data) => {
      if (data.successIds && data.successIds.length) {
        successToast(
          __rootT('archived_successfully', {
            resource: __rootTc('asset', ids.length),
          })
        )
      }
      return {
        failed: Object.keys(data.failerIds || {}).map((key) => ({
          id: parseInt(key, 10),
          message: data.failerIds[key].userMessage,
        })),
      }
    })
}

export function bulkUnarchiveApi(moduleName, ids) {
  return api.post(
    `/asset/${moduleName}/bulk/restore`,
    { objectIds: ids },
    {
      message: __rootT('updated_successfully', {
        resource: __rootTc('asset', ids.length),
      }),
    }
  )
}

export function generateBarcodeApi(data, moduleName) {
  return api
    .post(`/asset/${moduleName}/barcode`, data, {
      message: __rootT('generated_successfully', {
        resource: __rootTc('barcode'),
      }),
    })
    .then((data) => {
      return data.objectList ? data.objectList || [] : []
    })
}

export function bulkPermanentDeleteApi(moduleName, ids) {
  return api.delete(`/asset/${moduleName}/bulk/permanentdelete`, {
    data: { objectIds: ids },
    message: __rootT('deleted_successfully', {
      resource: __rootTc('asset', ids.length),
    }),
  })
}

export function markForNotificationSoftwareApi(ids) {
  return api.post(
    `/software_asset/notify`,
    { objectIds: ids },
    {
      message: __rootT('mark_for_notification_successfully', {
        resource: __rootTc('asset_software'),
      }),
    }
  )
}

export function consolidateSoftwareApi(primaryId, secondaryIds) {
  return api.post(
    `software_asset/consolidate`,
    {
      primaryId,
      secondaryIds,
    },
    {
      message: __rootTc('consolidate_successfully'),
    }
  )
}

export function changeAssociateBracodeApi(moduleName, id) {
  return api.get(`/asset/${moduleName}/${id}/associate/barcode`)
}

export function generateAssociateBarcodeApi(moduleName, ids) {
  return api
    .post(
      `asset/${moduleName}/associate/barcode`,
      {
        objectIds: ids,
      },
      {
        message: __rootT('generated_successfully', {
          resource: __rootTc('barcode'),
        }),
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
      }
    })
}

// property component related apis

export function getPropertyGroupApi(id) {
  return api.get(`/asset/${id}/assetPropertyGroup`)
}

export function searchAssetPropertiesApi(id, type) {
  return api
    .get(`/asset/${id}/assetPropertyGroup/${type}`, {
      notification: {
        error: {
          message: __rootT('error'),
        },
      },
    })
    .then((data) => {
      return {
        items: data ? ([data] || []).map(transformAssetProperties) : [],
        total: 1,
      }
    })
}

export function updatePropertyGroupApi({ id, ...data }) {
  return api.patch(`/asset/assetPropertyGroup/${id}`, data, {
    message: __rootT('updated_successfully', {
      resource: __mtc(data.model),
    }),
  })
}

export function getComponentsApi(id) {
  return api.get(`/asset/${id}/discoveryComponent`)
}

export function searchAssetComponentsApi(
  qualList = [],
  id,
  type,
  limit,
  offset
) {
  return api
    .post(
      `/asset/${id}/discoveryComponent/${type}`,
      {
        qualDetails: buildFlatQualificationStructure(qualList),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.discoveryComponentRestList
          ? (data.discoveryComponentRestList || []).map(transformAssetComponent)
          : [],
        total: data.totalCount,
      }
    })
}

export function createAssetComponentApi(assetId, data) {
  return api
    .post(
      `/asset/${assetId}/discoveryComponent`,
      transformAssetComponentForServer(data),
      {
        message: __rootT('added_successfully', {
          resource: __mtc(data.type),
        }),
      }
    )
    .then(transformAssetComponent)
}

export function getAssetComponentApi(
  type,
  componentId,
  additionalUrlParams = {}
) {
  return api
    .get(`/asset/discoveryComponent/${type}/${componentId}`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then(transformAssetComponent)
}

export function updateAssetComponentApi({ id, ...data }) {
  return api
    .patch(
      `/asset/discoveryComponent/${id}`,
      transformAssetComponentForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __mtc(data.type),
        }),
      }
    )
    .then(transformAssetComponent)
}

export function deleteAssetComponentApi(componentType, id) {
  return api.delete(`/asset/discoveryComponent/${componentType}/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __mtc(componentType),
    }),
  })
}

export function getMyAssetApi(additionalUrlParams = {}) {
  return api.post(
    `/cportal/myasset/search/byqual`,
    {},
    {
      params: {
        ...additionalUrlParams,
      },
    }
  )
}

export function getScanHistoryApi(filterFactor, refId, pageSize, offset) {
  return api
    .post(
      `/asset/${refId}/assetscanaudit/search/byqual`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'createdTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                  ...(filterFactor.discoveryMethod &&
                  filterFactor.discoveryMethod !== 'all'
                    ? [
                        buildRelationalQualificationStructure(
                          'discoveryMethod',
                          'equal',
                          filterFactor.discoveryMethod,
                          'enum',
                          'db'
                        ),
                      ]
                    : []),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformScanHistory),
        total: data.totalCount,
      }
    })
}

export function getChangeLogApi(filterFactor, refId, pageSize, offset) {
  return api
    .post(
      `/asset/${refId}/changelog/search/byqual`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'createdTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          componentType: filterFactor.componentType,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformChangeLog),
        total: data.totalCount,
      }
    })
}

export function getAssetChangeLogComponentDataApi(
  componentId,
  additionalUrlParams = {}
) {
  return api.get(`/asset/changelog/componentdata/${componentId}`, {
    params: {
      ...additionalUrlParams,
    },
  })
}

export function getWolHistoryApi(filterFactor, refId, pageSize, offset) {
  return api
    .post(
      `/asset/${refId}/wolhistory/search/byqual`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'createdTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWolHistory),
        total: data.totalCount,
      }
    })
}

export function searchAssetInstallationApi(
  id,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/software_asset/${id}/installation/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function addSoftwareComponentApi(id, itemIds) {
  return api.post(`hardware_asset/${id}/addSoftware`, {
    objectIds: itemIds,
  })
}
export function fetchSoftwareUninstallTaskApi(id) {
  return api
    .post(
      `/asset_software/${id}/uninstalltionTask/search/byqual`,
      {},
      {
        notify: false,
        message: __rootT('scan_submitted_successfully'),
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformSoftwareUninstallTask)
          : [],
        total: data.totalCount,
      }
    })
}

export function updateUninstallTaskApi(data) {
  return api
    .patch(
      `/asset_software/uninstalltionTask/${data.id}`,
      transformSoftwareUninstallTasForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('asset'),
        }),
      }
    )
    .then(transformSoftwareUninstallTask)
}

export function scanNowApi(id) {
  return api.post(
    `/hardware_asset/scannow/${id}`,
    {},
    {
      notify: false,
      message: __rootT('scan_submitted_successfully'),
    }
  )
}

export function systemActionApi(systemaction, id) {
  return api.post(
    `/hardware_asset/${systemaction}/${id}`,
    {},
    {
      notify: false,
      message: __rootT('system_action_submitted_successfully'),
    }
  )
}

export function wakeUpNowApi(id) {
  return api.get(`/asset/${id}/wakeupnow`)
}

export function searchSoftwareComponentApi(
  id,
  qualifications,
  limit,
  offset,
  sortedColumn
) {
  return api
    .post(
      `/hardware_asset/${id}/getSoftwareAsset/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getVmDetailsApi(id, limit, offset) {
  return api
    .get(`/asset_hardware/${id}/vmdetails`, {
      params: {
        offset: offset || 0,
        size: limit === undefined ? 1000 : limit,
      },
      notify: false,
    })
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformVmForDetail)
          : [],
        total: data.totalCount,
      }
    })
}

export function scanBarcodeApi(barcode) {
  return api.get(`/asset/scanbarcode/${barcode}`).then((data) => ({
    item: data ? transformAssetForDetail(data) : null,
  }))
}

export function getSnmpPropertyValueApi(assetId, type) {
  return api.get(`/asset/${assetId}/snmpPropertyValue/${type}`).then((data) => {
    return {
      items: data.objectList
        ? (data.objectList || []).map(transformSnmpPropertyValue)
        : [],
      total: data.totalCount,
    }
  })
}

export function searchSoftwareAssetsWithoutOSApi(
  qualifications,
  limit,
  offset
) {
  return api
    .post(
      `/asset_software/withoutOS/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function searchSoftwareAssetsSoftwareLicenseApi(
  qualifications,
  limit,
  offset
) {
  return api
    .post(
      `/asset_software/softwarelicense/search/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}
export function establishRDPSessionApi(id) {
  return api
    .get(`/rdp/obtaintoken/${id}`, {
      // notification: {
      //   error: {
      //     message: __rootT('rdp_auth_fail'),
      //   },
      // },
    })
    .then((data) => {
      localStoreSetItem('GUAC_AUTH', JSON.stringify(data.rdpToken)) // localstorage add item
      return data
    })
}
export function getRDPRecordingApi(assetName) {
  return api.get(`/rdp/recording/${assetName}`).then((data) => {
    return (data || []).map((i) =>
      transformRDPRecording({ ...i, id: generateId() })
    )
    // return {
    //   items: (data || []).map((i) =>
    //     transformRDPRecording({ ...i, id: generateId() })
    //   ),
    //   total: 1,
    // }
  })
}

export function convetToM4vApi(assetName, fileName) {
  return api.post(
    `/rdp/convertToM4v/${assetName}`,
    {
      fileName,
    },
    {
      message: __rootT('converted_successfully', {
        resource: __rootTc('file'),
      }),
    }
  )
}

const transformRelation = (r) => ({
  id: r.relationshipId,
  resourceId: r.destinationId,
  model: r.destinationModel,
  name: r.destinationName,
  displayName: r.assetDisplayName,
  assetTypeId: r.assetType,
  assetIconName: /^\d+$/.test(r.assetIconName)
    ? buildImageUrlFromRefFileName(r.assetIconName)
    : `${(r.assetIconName || '').split(' ').join('').toLowerCase() || ''}Icon`,
  relation: r.relationshipName,
  arrowInward: r.towardsAsked,
  sourceId: r.sourceId,
})

export function getAssetRelationshipsAsTreeApi(model, id) {
  return api
    .get(`/asset/assetrelationship/${model}/${id}`, {
      params: { treeview: true },
    })
    .then((data) => {
      return transformRecursive(
        data,
        transformRelation,
        'relationList',
        null,
        null
      )
    })
}

export function getAssetRelationshipsApi(model, id, params) {
  return api
    .get(`/asset/assetrelationship/${model}/${id}`, { params })
    .then((data) => data.map(transformRelation))
}

export function createAssetRelationshipApi(data) {
  const destinationModel =
    ['technician', 'requester'].indexOf(data.destinationModel) >= 0
      ? 'user'
      : data.destinationModel
  const d = {
    sourceId: data.sourceId,
    destinationId: data.destinationId,
    sourceModel: data.sourceModel,
    destinationModel,
    relationTypeId: data.relationId,
    inverse: data.relationshipType === 'inverse',
  }
  return api.post(`/asset/assetrelationship`, d, {
    message: __rootT('added_successfully', {
      resource: __mtcp('asset_relationship'),
    }),
  })
}

export function createAssetRelationshipBulkApi(data) {
  const destinationModel =
    ['technician', 'requester'].indexOf(data.destinationModel) >= 0
      ? 'user'
      : data.destinationModel
  const requestedData = []
  data.destinationId.forEach((d) => {
    const rest = {
      sourceId: data.sourceId,
      destinationId: data.destinationId,
      sourceModel: data.sourceModel,
      destinationModel,
      relationTypeId: data.relationId,
      inverse: data.relationshipType === 'inverse',
    }
    requestedData.push({
      ...rest,
      destinationId: d,
    })
  })

  return api.post(`/asset/assetrelationship/bulk`, requestedData, {
    message: __rootT('added_successfully', {
      resource: __mtcp('asset_relationship'),
    }),
  })
}

export function deleteAssetRelationshipApi(id) {
  return api.delete(`/asset/assetrelationship/${id}`, {
    message: __rootT('removed_successfully', {
      resource: __mtcp('asset_relationship'),
    }),
  })
}

export function scanQrCodeApi(qrcode) {
  return api
    .post(
      `/asset/scanqrcode`,
      {
        assetId: qrcode,
      },
      {
        notify: false,
      }
    )
    .then((data) => ({
      item: data ? transformAssetForDetail(data) : null,
    }))
}

export function generateQrCodeApi(moduleName, ids) {
  return api
    .post(
      `${
        isPortalLogin() ? '/cportal' : ''
      }/asset/${moduleName}/associate/qrcode`,
      {
        objectIds: ids,
      },
      { notify: false }
    )
    .then((data) => {
      return data.qrMap || []
    })
    .catch((e) => {
      errorToast(((e.response || {}).data || {}).userMessage)
    })
}
// manually variance detection api
export function varianceDetectionManuallyApi(assetId) {
  return api.get(`/varinaceConfig/manualdetection/${assetId}`)
}

// summary baseline related apis

export function checkSummaryBaselineRelationExistApi(assetId) {
  return api.get(`/assetbaseline/exist/${assetId}`)
}
export function getSummaryBaselineApi(assetId) {
  return api.get(`/baseline/byasset/${assetId}`).then((data) => {
    return {
      items: [transformSummaryBaseline(data || {})],
      total: data.totalCount || 0,
    }
  })
}
export function getSummaryBaselinesApi(assetTypeId, criteria) {
  return api
    .post(`/baseline/search/byassettype`, criteria, {
      params: { assettypeId: assetTypeId },
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformSummaryBaseline),
        total: data.totalCount,
      }
    })
}
export function createSummaryBaselinesApi(assetId, baselineId) {
  return api
    .get(
      `/baseline/addbaseline/${assetId}/${baselineId}`,
      {},
      {
        message: __rootT('added_successfully', {
          resource: __rootTc('baseline'),
        }),
      }
    )
    .then(transformSummaryBaseline)
}

export function updateSummaryBaselinesApi(assetId, baselineId) {
  return api
    .get(
      `/baseline/changebaseline/${assetId}/${baselineId}`,
      {},
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('baseline'),
        }),
      }
    )
    .then(transformSummaryBaseline)
}

export function deleteSummaryBaselinesApi(assetId) {
  return api.delete(`/baseline/removebaseline/${assetId}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('baseline'),
    }),
  })
}
export function getSummaryVariancesApi(assetId, qualifications, limit, offset) {
  return api
    .post(
      `/assetvariance/${assetId}`,
      {
        ...(qualifications || {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformSummaryVariance)
          : [],
        total: data.totalCount,
      }
    })
}
export function VarianceApprovalApi(
  varianceIds,
  assetId,
  relatedModuleName,
  relatedIds
) {
  return api
    .post(`/varinace/approve`, {
      model: relatedModuleName,
      varianceIds: varianceIds,
      assetId: assetId,
      objectId: relatedIds,
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformSummaryVariance),
        total: data.totalCount,
      }
    })
}
export function VarianceRejectApi(varianceIds, assetId, data) {
  return api
    .post(`/varinace/reject`, {
      //  model: relatedModuleName,
      varianceIds: varianceIds,
      assetId: assetId,
      // objectId: relatedIds,
      changeRest: transformTicketForServer(data),
    })
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformSummaryVariance),
        total: data.totalCount,
      }
    })
}
// history
export function getBaselineHistoryApi(qualifications, limit, offset) {
  return api
    .post(
      `/assetbaseline/search/byqual`,
      { ...(qualifications || {}) },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformBaselineHistory)
          : [],
        total: data.totalCount,
      }
    })
}
export function compareBaselienApi(baseline1Id, baseline2Id) {
  return api
    .get(`/baseline/compare`, {
      params: {
        baseline1: baseline1Id,
        baseline2: baseline2Id,
      },
    })
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformBaselineCompareInfo)
          : [],
        total: data.totalCount || 0,
      }
    })
}
export function getVarianceHistoryApi(assetId, filterFactor, limit, offset) {
  return api
    .post(
      `/assetvariance/${assetId}`,
      {
        qualDetails:
          filterFactor.fromValue && filterFactor.toValue
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'createdTime',
                    'between',
                    [filterFactor.fromValue, filterFactor.toValue],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformVarianceHistory)
          : [],
        total: data.totalCount,
      }
    })
}
// cost related apis

export function getAssetDepreciationCostInfoApi(
  moduleName,
  assetId,
  additionalUrlParams = {}
) {
  return api
    .get(`/asset/${moduleName}/${assetId}/assetcost/info`, {
      params: {
        ...additionalUrlParams,
      },
    })
    .then(transformCostInfo)
}

export function searchFinancialCostApi(moduleName, assetId, limit, offset) {
  return api
    .post(
      `/asset/${moduleName}/${assetId}/assetcost/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformCost)
          : [],
        total: data.totalCount,
      }
    })
}

export function createFinancialCostApi(moduleName, assetId, data) {
  return api
    .post(
      `/asset/${moduleName}/${assetId}/assetcost`,
      transformCostForServer(data),
      {
        message: __rootT('added_successfully', {
          resource: __rootTc('cost'),
        }),
      }
    )
    .then(transformCost)
}

export function updateFinancialCostApi(moduleName, assetId, data) {
  return api
    .patch(
      `/asset/${moduleName}/${assetId}/assetcost/${data.id}`,
      transformCostForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('cost'),
        }),
      }
    )
    .then(transformCost)
}

export function deleteFinancialCostApi(moduleName, assetId, costId) {
  return api.delete(`/asset/${moduleName}/${assetId}/assetcost/${costId}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('cost'),
    }),
  })
}

//  depreciation related api

export function getAssetDepreciationInfoApi(
  moduleName,
  assetId,
  additionalUrlParams = {}
) {
  return api
    .get(`/asset/${moduleName}/assetdepreciation/byid/${assetId}`, {
      params: {
        ...additionalUrlParams,
      },
      notify: false,
    })
    .then(transformDepreciation)
}

export function assetDepreciationTableApi(
  moduleName,
  assetId,
  additionalUrlParams = {}
) {
  return api
    .post(
      `/asset/${moduleName}/${assetId}/assetdepreciation/table`,
      {},
      {
        params: {
          ...additionalUrlParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
        notify: false,
      }
    )
    .then((data) => {
      return data.assetDepreciationScheduleResponses
        ? (data.assetDepreciationScheduleResponses || []).map(
            transformAssetDepreciationForList
          )
        : []
    })
}

export function reconcileAssetApi(moduleName, ids) {
  return api.post(
    `/asset/reconcile`,
    {
      objectIds: ids,
    },
    {
      message: __rootT('reconcile_successfully', {
        resource: __rootTc(moduleName, 2),
      }),
    }
  )
}

export function getAssetMovementAndRepairHistoryApi(
  filterFactor,
  moduleName,
  assetId,
  type,
  limit,
  offset
) {
  return api
    .post(
      `/asset/movement/${type}/${moduleName}/${assetId}`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    type === 'history' ? 'movementDate' : 'createdTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          sort_by: type === 'history' ? 'movementDate' : 'createdTime',
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformMovementAndRepairHistory)
          : [],
        total: data.totalCount,
      }
    })
}

// Rdp History
export function getRdpHistoryApi(filter, limit, offset) {
  return api
    .post(
      `/rdp/sessiondetails/search/byqual`,
      {
        qualDetails:
          filter.name || filter.enabled || filter.selectedName || filter.ids
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          sort_by: 'startTime',
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformRdpHistory)
          : [],
        total: data.totalCount,
      }
    })
}

export function getUsedByOrLocationApi(filters, limit, offset) {
  return api
    .post(
      `/assetFieldTransitionHistory/search/byqual`,
      {
        qualDetails:
          filters.type ||
          filters.moduleName ||
          filters.ids ||
          filters.approvalStatus
            ? buildQualificationsForHistoryDrawers(filters)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformUsedByOrLocationHistory)
          : [],
        total: data.totalCount,
      }
    })
}

export function getHardwareAssetTypeApi(qualifications, limit, offset) {
  return api
    .post(
      'asset/parentVmHost/search/byqual',
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetForList)
          : [],
        total: data.totalCount,
      }
    })
}

export function getGeolocationHistory(assetId, filterFactor, pageSize, offset) {
  return api
    .post(
      `/asset/geohistory/${assetId}/searchbyqual`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'historyLogTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
          sort_by: 'historyLogTime',
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformGeolocationHistory),
        total: data.totalCount,
      }
    })
}

export function getGeolocationCoordinatesApi(filter, pageSize, offset) {
  return api
    .post(
      `/asset/locateonmap`,
      {
        qualDetails: filter.ids
          ? buildAssetIdQualifications(filter)
          : undefined,
      },
      {
        params: {
          size: pageSize || 1000,
          offset: offset || 0,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data || []).map(transformGeolocationCoordinates),
        total: data.totalCount,
      }
    })
}

export function searchAssetSystemActionApi(refId, limit, offset) {
  return api
    .post(
      `/asset/asset_hardware/${refId}/systemaction/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetSystemAction)
          : [],
        total: data.totalCount,
      }
    })
}

export function getgetAssetUtilizationHistoryApi(
  filterFactor,
  assetId,
  limit,
  offset
) {
  return api
    .post(
      `asset/${assetId}/assetusagedateails/search/byqual`,
      {
        qualDetails:
          filterFactor.startDate && filterFactor.endDate
            ? buildFlatQualificationStructure(
                [
                  buildRelationalQualificationStructure(
                    'updatedTime',
                    'between',
                    [filterFactor.startDate, filterFactor.endDate],
                    'long',
                    'db'
                  ),
                ].filter(Boolean)
              )
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          sort_by: 'updatedTime',
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: data.objectList
          ? (data.objectList || []).map(transformAssetUtilizationHistory)
          : [],
        total: data.totalCount,
      }
    })
}

export function downloadDepreciationApi(moduleName, data) {
  return api.post(`analytics/download/${moduleName}/data`, data, {
    responseType: 'blob',
    notify: false,
  })
}
